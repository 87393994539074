<template>

  <!-- Breadcroumbs start -->
  <Breadcrumbs title="Checkout" />
  <!-- Breadcroumbs end -->

  <!-- Checkout start -->
  <div class="bee-content-block checkbox-block">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="track-result-step pt0">
            <ul class="track-progress step-number3">
              <li class="progress-active">Review your Order</li>
              <li class="">Payment</li>
              <li class="">Done</li>
            </ul>
          </div>
          <h4 class="mb20">1. Select your shipping information:</h4>
          <div class="defalt-address">
            <h6>
              Defalt Address
              <span
                ><a href=""><i class="fa fa-edit" aria-hidden="true"></i></a
              ></span>
            </h6>
            <div class="my-account-section">
              <ul>
                <li><span>Name:</span><span>Razu Ahmed</span></li>
                <li>
                  <span>Address:</span
                  ><span
                    >It was popularised in the 1960s with the release of
                    Letraset sheets containing.</span
                  >
                </li>
                <li><span>Mobile:</span><span>+88 01911837404</span></li>
              </ul>
            </div>
            <a href="" class="add-address"
              ><i class="fa fa-plus" aria-hidden="true"></i> Add more new
              address</a
            >
          </div>
          <h4 class="mb20">2. Review and confirm your order (3 items):</h4>
          <div
            v-for="(item, index) in this.$store.state.cart"
            :key="index"
            class="order-section"
          >
            <div class="order-top justify-content-end">
              <div class="order-id mr-auto">
                Added to cart: <b>11 November 2017 </b>
              </div>
              <div class="delete-order">
                <a
                  ><i
                    class="fa fa-trash-o"
                    aria-hidden="true"
                    @click.prevent="this.$store.commit('removeFromCart', item)"
                  ></i
                ></a>
              </div>
            </div>
            <div class="order-details">
              <div class="order-product">
                <div class="order-product-img">
                  <img :src="item.productImage" alt="Product 1" />
                </div>
                <div class="order-product-title">
                  <a href="">
                    <h5>{{ item.productName }}</h5>
                    <!-- <p v-html="item.productDescription"> -->
                    <!-- <p >
{{ shortDescription(item.productDescription)}}
                    </p> -->
                  </a>
                </div>
              </div>
              <div class="order-status">
                Price: <b> {{ currency(item.productPrice) }}</b>
                <div class="quantity">
                  <span>Quantity:</span>
                  <span>
                    <input
                      v-model="item.productQuantity"
                      type="text"
                      class="form-control"
                    />
                  </span>
                </div>
              </div>
              <div class="order-action">
                Subtotal:
                <b>{{(subTotal(item.productQuantity, item.productPrice)) }}</b>
                <!-- <a href="" class="btn-action btn-buy">Buy Now</a> -->
              </div>
            </div>
          </div>

          <h4 class="mb20">3. Payment method</h4>
          <div class="payment-method css-radio-option">
            <ul>
              <li>
                <input
                  type="radio"
                  name="radiog_dark"
                  id="payment-option"
                  class="css-radio"
                  checked="checked"
                />
                <label for="payment-option" class="css-label"
                  >Pay with <span><i class="fa fa-cc-paypal"></i></span
                ></label>
              </li>
              <li>
                <input
                  type="radio"
                  name="radiog_dark"
                  id="payment-option2"
                  class="css-radio"
                />
                <label for="payment-option2" class="css-label"
                  >Other payment methods
                  <span
                    ><i class="fa fa-cc-visa"></i
                    ><i class="fa fa-cc-mastercard"></i
                    ><i class="fa fa-cc-amex"></i
                    ><i class="fa fa-cc-discover"></i></span
                ></label>
              </li>
            </ul>
          </div>
          <div class="cart-total-section">
            <h3>Cart totals</h3>
            <div class="cart-total-block">
              <ul>
                <li><span>Subtotal:</span><span>{{ this.$store.getters.totalPrice }}</span></li>
                <li><span>Shipping:</span><span>$1.00</span></li>
                <li><span>All Total:</span><span>{{ this.$store.getters.totalPrice }}</span></li>
              </ul>
            </div>
            <button type="submit" class="btn btn-submit">
              Confirm &amp; pay
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Checkout End -->

  <Newsletter />

</template>

<script>
// @ is an alias to /src
// import BeeSlider from '@/components/Slider.vue';

import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Newsletter from "@/components/Newsletter.vue";
import helpers from "@/includes/helpers";

export default {
  name: "Checkout",
  components: {
    Breadcrumbs,
    Newsletter,
  },
  methods: {
    shortDescription(description) {
      return description.substring(3, 200);
    },
    subTotal(qty, price) {
      const sub = qty * price;
      return this.currency(sub);
    },
    currency(value) {
      return helpers.withDecimalAndCurrency(value);
    },

  },

};
</script>
